<template>
  <v-col cols="12" sm="12" md="6" lg="6" xl="4">
    <v-hover v-slot="{ hover }">
      <v-card
        v-bind:class="[
          hover ? 'card-shadow-hover' : 'card-shadow',
          ' overflow-hidden',
          'fill-height',
        ]"
        v-on:click="$emit('click', $event)"
      >
        <v-container>
          <v-card-title class="pb-0">
            <span :class="$touchable ? 'underline--magical' : ''"
              ><slot name="title" v-bind:hover="hover"> </slot
            ></span>
            <v-spacer></v-spacer>
            <v-slide-x-transition>
              <i
                class="ri-arrow-right-line primary--text hidden-xs-only"
                style="font-size: 1.5em"
                v-show="hover"
              >
              </i>
            </v-slide-x-transition>
          </v-card-title>
          <v-row
            align="center"
            :class="$vuetify.breakpoint.mdAndUp ? '' : ''"
          >
            <v-col>
              <v-card-text>
                <slot name="text"></slot>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="auto">
              <lord-icon
                v:bind="$attrs"
                :src="`https://cdn.lordicon.com/${icon}.json`"
                :trigger="hover ? 'loop' : ''"
                :colors="`primary:#121331,secondary:${$vuetify.theme.themes.light.primary}`"
                stroke="20"
                style="width: 100px; height: 100px"
              >
              </lord-icon>
            </v-col>
          </v-row>

          <div v-if="$touchable || $vuetify.breakpoint.xsOnly" class="py-5">
            <v-spacer></v-spacer>
            <v-btn
              depressed
              absolute
              bottom
              right
              color="primary"
              small
              outlined
              class="normal-caps"
              v-on:click="$emit('click', $event)"
            >
              View Products &nbsp;
              <v-icon color="primary lighten-1" small
                >mdi-arrow-right-thick</v-icon
              >
            </v-btn>
            <v-spacer></v-spacer>
          </div>
        </v-container>
      </v-card>
    </v-hover>
  </v-col>
</template>
<script>
export default {
  name: "product-card-main",
  components: {},
  props: {
    icon: String,
    color: String,
  },
  data: () => {
    return {};
  },
};
</script>
<style scoped>
* {
  transition: 0.5s;
}
</style>