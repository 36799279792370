<template>
  <div>
    <v-scroll-y-transition>
      <v-row v-if="mode == 'product'">
        <v-col class="pb-0 mb-0" cols="12">
          <span class="text-h6 grey--text text--darken-1"
            >What product are you looking for ?</span
          >
        </v-col>
        <template v-for="(product, idx) in products">
          <product-card-main
            color="success"
            :icon="product.icon"
            :key="`product-${idx}`"
            v-on:click="
              productIdx = idx;
              mode = 'info';
            "
          >
            <template v-slot:title="{ hover }">
              <span
                :class="[
                  hover ? 'underline--magical-hover-grow' : '',
                  'underline--magical-hover',
                ]"
                >{{ product.title }}</span
              >
            </template>
            <template v-slot:text>
              {{ product.text }}
            </template>
          </product-card-main>
        </template>
      </v-row>
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <div v-if="mode == 'info'">
        <v-row justify="space-between">
          <v-col class="pb-0 mb-0" cols="12" md="auto">
            <v-btn text small v-on:click="mode = 'product'"
              ><v-icon small>mdi-arrow-left</v-icon> &nbsp; Back to products
            </v-btn>
          </v-col>

          <v-col cols="12" md="auto">
            <span class="caption grey--text text--darken-1">
              Manage your policy with Bonsai and get cashback!
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    text
                    x-small
                    class="normal-caps"
                    >Find out more</v-btn
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title>
                      <span class="underline--magical"
                        >Get monthly cashback with Bonsai.</span
                      >
                      <v-spacer></v-spacer>
                      <v-btn icon v-on:click="dialog.value = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </v-card-title>
                    <v-card-text>
                      <p>
                        You will qualify for a monthly cashback reward when you
                        join Bonsai and let us manage your policies. The
                        cashback is calculated based on the type and monthly
                        premium spend of each policy.
                      </p>
                      <p>
                        Your cashback is allocated to an interest-bearing
                        virtual rewards card on your Bonsai account. You can use
                        your accumulated rewards to buy items anywhere on the
                        internet, or you can leave them to grow at a 6% annual
                        growth rate.
                      </p>
                      <p>Qualifying policy types include:</p>
                      <ul>
                        <li>Life insurance</li>
                        <li>Short term insurance</li>
                        <li>Medical aid</li>
                        <li>Gap cover</li>
                        <li>Unit trusts</li>
                        <li>Tax-free savings plans</li>
                        <li>Retirement annuities</li>
                        <li>Preservation funds</li>
                        <li>Offshore investments</li>
                        <li>Employee benefits</li>
                      </ul>
                      <br />
                      <p>
                        To get started, log in to your Bonsai account, navigate
                        to the rewards page and follow the instructions to learn
                        what monthly cashback you will qualify for.
                      </p>
                      <small class="capti">
                        *Please note: Bonsai can only provide a cashback on
                        policies for which we can become brokers on record.
                        Therefore, we can only offer cashback on policies from
                        our list of product providers.
                      </small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer> </v-spacer>
                      <v-btn
                        depressed
                        color="primary"
                        small
                        outlined
                        class="normal-caps"
                        v-on:click="$emit('click', $event)"
                      >
                        Get Started &nbsp;
                        <v-icon color="primary lighten-1" small
                          >mdi-arrow-right-thick</v-icon
                        >
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <template v-for="(item, itemIdx) in products[productIdx].items">
            <v-col cols="12" :key="`pi-${itemIdx}`">
              <v-card class="card-shadow">
                <v-container>
                  <v-card-title
                    ><span class="underline--magical">{{ item.title }}</span>
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row align="center">
                      <v-col cols="auto">
                        <lord-icon
                          v:bind="$attrs"
                          :src="`https://cdn.lordicon.com/${item.icon}.json`"
                          :colors="`primary:#121331,secondary:${$vuetify.theme.themes.light.primary}`"
                          stroke="20"
                          class="ml-4"
                          style="width: 65px; height: 65px"
                          v-if="item.icon"
                        >
                        </lord-icon>
                      </v-col>
                      <v-col>
                        <v-card-text>
                          {{ item.text }}
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                     <v-btn
                      depressed
                      color="info darken-3"
                      small
                      outlined
                      class="normal-caps"
                      :to="`/get-quote?item=${item.title}`"
                    >
                      Learn More &nbsp;
                      <v-icon color="info darken-1" small
                        >mdi-information-outline</v-icon
                      >
                    </v-btn>
                    <v-btn
                      depressed
                      color="primary"
                      small
                      outlined
                      class="normal-caps"
                      :to="`/get-quote?item=${item.title}`"
                    >
                      Get a Quote &nbsp;
                      <v-icon color="primary lighten-1" small
                        >mdi-arrow-right-thick</v-icon
                      >
                    </v-btn>
                  </v-card-actions>
                </v-container>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import ProductCardMain from "./products/product-card-main.vue";
import products from "@/library/products";
export default {
  name: "About",

  components: {
    ProductCardMain,
  },
  data: () => {
    return {
      tab1: 0,
      tab2: 0,
      mode: "product",
      productIdx: 0,
      products: products,
    };
  },
};
</script>
