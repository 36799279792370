export default [
    {
        icon: 'dugfwxlj',
        title: 'Long term insurance',
        text: 'These products include life insurance, lump-sum disability, severe illness, income protection and more.',
        items: [
            { title: 'Life assurance', icon: 'aswgwjjg', text: '' },
            { title: 'Lump-sum disability', icon: 'jcjjhrgg', text: '' },
            { title: 'Severe illnes', icon: 'txyizhlr', text: '' },
            { title: 'Income protection', icon: 'ftqlqjlw', text: '' },
            { title: 'Funeral cover', icon: 'dfynpdxo', text: '' },
        ]
    },
    {
        icon: 'hbavnglv',
        title: 'Personal short term insurance',
        text: 'These products include personal cove for your vehicles, home contents, building, portable possessions, personal accident and injury, personal liability and more.',
        items: [
            { title: 'Vehicle insurance', icon: 'irlnttvd', text: '' },
            { title: 'Building', icon: 'gmzxduhd', text: '' },
            { title: 'Contents', icon: '', text: '' },
            { title: 'All risk', icon: '', text: '' },
            { title: 'Liability', icon: '', text: '' },
            { title: 'Pet cover', icon: '', text: '' },
            { title: 'Medical insurance', icon: '', text: '' }
        ]
    },
    {
        icon: 'boagnaty',
        title: 'Business short term insurance',
        text: 'These products include cover for your business, namely: loss or damage due to theft, vandalism, flood, fire and other dangers,  business interruption, accounts receivable, liability and more',
        items: [
            { title: 'Accidental damage', icon: '', text: '' },
            { title: 'Business interruption', icon: '', text: '' },
            { title: 'Theft', icon: '', text: '' },
            { title: 'Building', icon: '', text: '' },
            { title: 'Glass', icon: '', text: '' },
            { title: 'All risk', icon: '', text: '' },
            { title: 'Money', icon: '', text: '' },
            { title: 'Fire', icon: '', text: '' },
            { title: 'Personal accident', icon: '', text: '' },
            { title: 'Theft by employee', icon: '', text: '' },
            { title: 'Office contents', icon: '', text: '' },
            { title: 'Public liability', icon: '', text: '' },
            { title: 'Office electronics', icon: '', text: '' },
            { title: 'Goods in transit', icon: '', text: '' },
            { title: 'Vehicles', icon: '', text: '' },
            { title: 'Machinery breakdown', icon: '', text: '' },
            { title: 'Specialist insurance', icon: '', text: '' },
        ]
    },
    {
        icon: 'czdsqfzq',
        title: 'Investments',
        text: ' These products include unit trusts, tax-free savings plans, endowments, retirement annuities, pension & provident preservation funds, offshore investing and more.',
        items: [
            { title: 'Unit trusts', icon: '', text: '' },
            { title: 'Tax free savings', icon: '', text: '' },
            { title: 'Endowments', icon: '', text: '' },
            { title: 'Retirement annuities', icon: '', text: '' },
            { title: 'Preservation funds', icon: '', text: '' },
            { title: 'Guaranteed return plans', icon: '', text: '' },
            { title: 'Living annuity', icon: '', text: '' },
            { title: 'Fixed annuity', icon: '', text: '' },
            { title: 'Offshore investing', icon: '', text: '' },
        ]
    },
    {
        icon: 'xbsabxuj',
        title: 'Medical cover',
        text: 'These products include medical aid plans with or without savings accounts, medical insurance, gap cover and more.',
        items: [
            { title: 'Medical aid', icon: '', text: '' },
            { title: 'Medical insurance', icon: '', text: '' },
            { title: 'Gap cover', icon: '', text: '' }
        ]
    },
    {
        icon: 'ufezupnm',
        title: 'Estate planning',
        text: ' These products include a Last Will & Testament, Living Will, Trust services and general estate planning.',
        items: [
            { title: 'Last will and testament', icon: '', text: '' },
            { title: 'Living will', icon: '', text: '' },
            { title: 'Estate planning', icon: '', text: '' },
            { title: 'Trusts', icon: '', text: '' }
        ]
    },
    {
        icon: 'oddigoor',
        title: 'Employee benefits',
        text: 'These products include pension and/or provident fund, group risk, healthcare and financial planning services for your employees.',
        items: [
            { title: 'Retirement solutions', icon: '', text: '' },
            { title: 'Risk solutions', icon: '', text: '' },
            { title: 'Healthcare benefits', icon: '', text: '' },
            { title: 'Estate planning benefits', icon: '', text: '' },
            { title: 'Financial wellness solutions', icon: '', text: '' },
        ]
    },
    {
        icon: 'djoczfvh',
        title: 'Rewards',
        text: 'Get rewarded by making Bonsai your preferred financial advisory firm. For every policy we help you manage, you will receive a monthly cashback paid into your free virtual card account.',
    }
]